<template>
<v-app>
  <div class="mt-4">
      <div>
          <!-- Download instructions -->
      <v-container>
      <h2 class="mt-2  instructions-m">Instructions for Windows <span class="small text-info">(x64 and x86)</span> </h2>
      <v-card class="mt-2">
          <div class="mx-3">
          <div class="mt-2">
              <!-- Instructions -->
              <v-list class="detail-listing">
                    <h6 class="text-secondary small mb-3 read-instructions">Read the instructions carefully to download Staff Timer Silent App</h6>
                   <v-list-item-subtitle>
                    <span class="numbring">1.</span> This command in your windows Command Prompt <code class="color-code"> echo {{this.$route.params.id}} > AppData/Local/com.sta</code> 
                  </v-list-item-subtitle> 
                   <v-list-item-subtitle>
                    <span class="numbring">2.</span> silent app for 64 or 32 bit from the download button below.
                  </v-list-item-subtitle> 
                   <v-list-item-subtitle>
                    <span class="numbring">3.</span> click on exe file and make sure that you click next without changing installation path.
                  </v-list-item-subtitle> 
                   <v-list-item-subtitle>
                    <span class="numbring">4.</span> successfully installed, click just one time on silentapp.exe file.
                  </v-list-item-subtitle> 
                   <v-list-item-subtitle>
                    <span class="numbring">5.</span> the app has no user interface, we don’t show any message saying “successfully installed” after you’ve installed the app. You’ll know that the app is successfully installed when you start seeing data from the computer that you installed it on appearing in your account on the Staff Timer App website. 
                  </v-list-item-subtitle> 
              </v-list>
           
          </div>
          <v-divider></v-divider>
              <!-- Download button -->
              <v-btn  class="mb-3 ml-3 instructions-download" target="_blank" href="https://api.stafftimerapp.com/download/app/invisible/win64" download elevation="0" outlined height="40" color="#2758f6">Download (x64)</v-btn>
              <v-btn  class="mb-3 ml-3 instructions-download" target="_blank" href="https://api.stafftimerapp.com/download/app/invisible/win32" download elevation="0" outlined height="40" color="#2758f6">Download (x86)</v-btn>
          </div>
         </v-card>
      </v-container>
         <!-- Download Faqs -->
         <div class="my-4">
         <v-container class="mt-3">
      <h2 class="mt-3 instructions-m">Download & Installation FAQ </h2>
      <v-card class="my-2">
          <template>
            <v-expansion-panels focusable inset>
                <!-- Question 1 -->
                <v-expansion-panel>
                <v-expansion-panel-header class="bold">
                    How will users be notified or confirm that the silent app is successfully installed on their machine?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    Once the app is successfully installed, your machine will start to send required data to your account,
                    from there you will know that the silent app is working successfully.
                </v-expansion-panel-content>
                </v-expansion-panel>
                <!-- Question 2 -->
                <v-expansion-panel>
                <v-expansion-panel-header class="bold">
                    How to uninstall the silent app?
                </v-expansion-panel-header>
                <v-expansion-panel-content>Remove the folder named <span class="bold"> SA </span> form your machine following this path C:\Users\USER_NAME\AppData\Roaming\Microsoft\Windows\Start Menu\Programs
                </v-expansion-panel-content>
                </v-expansion-panel>
                <!-- Question 3 -->
                <v-expansion-panel>
                <v-expansion-panel-header class="bold">
                    Can a user see the silent app installed on its machine?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    As this app has no Graphical User Interphase, so the user won't be able to see the app on their computers. However, user can just see the application's icon on their machines. 
                </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            </template>
         </v-card>
         </v-container>
         </div>
         <v-divider></v-divider>
         </div>
  </div>
</v-app>
</template>

<script>
export default {
  name: "download-windows-silentapp",
  methods:{
      showDownloadModal(){
          this.$refs.downloadModal.show()
      },
  }
};
</script>

<style scoped>
.numbring {
    float: left;
    padding-right: 9px;
}
.text-info{
color: #17a2b8;
}
.instructions-download {
    text-align: left !important;
    width: 170px;
    margin: 20px 0 20px 15px;
}
.read-instructions{
    color: #6c757d !important;
    font-weight: normal;
    font-size: 13px;
    text-align: left;
    padding-bottom: 15px;
}
.instructions-m{
    text-align: left;
    padding: 13px 0;
}
.detail-listing{
    text-align: left;
    padding: 10px 18px;
}
.detail-listing .v-list-item__subtitle{
    line-height: 34px;
    font-size: 16px;
    white-space: normal;
}
.color-code{
   color: #e83e8c !important;
}
.bold{
    font-weight: 600!important;
}
</style>
